//import manager from '@/utils/manager'
import api from '@/utils/api'

/**
 * 获取用户信息
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function accountInfo(params) {
    return api.get('wx/v1/ccb/account', {params: params})
}

/**
 * 支付包支付或paypel支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function payMix(params) {
    return api.post('wx/v1/official_account/pay', params)
}

/**
 * 公众号微信支付
 * @param orderNum
 * @returns {Promise<AxiosResponse<any>>}
 */
export function payWxRedirect(orderNum) {
    //    最新版公众号微信支付
    return api.post('wx/v1/official/common/wxpay', {order_num: orderNum, type: 1, project_id: 'sgtl'})
}

/**
 * 连连支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function payLianLian(params) {
    return api.post('lianLianPay/pay', params)
}

/**
 * mycard台湾支付
 * @param params
 * @returns {*}
 */
export function payMycard(params) {
    return api.post('mycardPay/pay', params)
}

/**
 * 获取拉起微信支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pay(params) {
    params.type = 2;
    params.project_id = 'sgtl';
    return api.post('wx/v1/official/common/wxpay', params)
}

export function pay2(params) {
    params.type = 2;
    params.project_id = 'sgtl';
    return api.post('wx/v1/official/common/wxpayv2', params)
}


/**
 * 订单创建接口
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function commonCreateOrder(params) {
    return api.post('wx/v1/ccb/offline-jzac', params)
}

export function getServerList(params) {
    return api.get('wx/v1/ccb/get-server', { params:params })
}

export function getRoleList(params) {
    return api.get('wx/v1/ccb/get-role', { params:params })
}
export function getGameId(params) {
    return api.get('wx/v1/ccb/get-game', { params:params })
}

/**
 * 档位获取
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function thirdProductList(params) {
    return api.get('wx/v1/ccb/money-list', {params:params})
}
